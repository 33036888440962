<template>
    <div class="zch-chat-body">
        <div class="zch-messages" v-bind:class="msgClass">
            <div class="zch-step-wrapper" v-bind:key="`step-${key}`" v-for="(step, key) in steps">
                <div v-bind:style="getCompletedWrapperMargin(key)">
                    <Message
                        :last-style="getLastStyle(zakMessage)"
                        :message-sender="messageSenders.ZAK"
                        v-bind:key="`message-zak-${key}`"
                        v-for="(zakMessage, key) in step.zakMessages"
                        :text="zakMessage.text"
                        :index="key"
                        :hint="step.hint"
                        :step="step"
                    />
                    <Message
                        :last-style="getLastStyle(userMessage)"
                        :message-sender="messageSenders.USER"
                        :step="step"
                        v-bind:key="`message-user-${key}`"
                        :index="key"
                        :text="userMessage.text"
                        v-for="(userMessage, key) in step.userMessages"
                    />
                </div>
            </div>
            <LoadingMessage
                v-if="!loadingMsgHidden"
                :messageSenderClass="getAnimMessageSenderClass"
                :messageStyle="`${getAnimMessageStyle} ${loadingMsgOpacity}`"
            />
            <div
                class="zch-answers-list"
                ref="answersList"
                v-bind:class="`${getAnswersListDisplay} ${getAnswerListInputClass} ${getAnswerDisplayType}`"
                v-bind:style="`${getAnswerListTypeStyle} ${getAnswerListStyle}`"
            >
                <div class="zch-answer-input" v-if="isSelectionRequiringInput">
                    <div v-if="isDisplayTypeSwitch" class="zch-answer-input-switch">
                        <span v-bind:class="switchLabelClass[0]">
                            {{ conversation.currencySymbol }}
                        </span>
                        <SwitchButton :callback="`onInputSwitchUpdate`" :style="switchStyle" />
                        <span v-bind:class="switchLabelClass[1]">g</span>
                    </div>
                    <input
                        @keyup.enter="submitAnswer"
                        autocapitalize="off"
                        autocomplete="off"
                        spellcheck="false"
                        autofocus
                        class="zch-input-field"
                        :type="getFieldInputType"
                        :pattern="getFieldPattern"
                        v-bind:placeholder="getInputPlaceholder"
                        v-model="userInput.inputFieldValue"
                    />
                </div>
                <div class="zch-answers" v-else v-bind:class="getAnswerDisplayType">
                    <div
                        @click="selectOption(key)"
                        class="zch-answer"
                        v-bind:class="`${userInput.fieldStyle[key]} ${getAnswerDisplayType} ${getAnswersLength}`"
                        v-bind:style="getAnswerStyle(input)"
                        v-bind:key="key"
                        v-for="(input, key) in userInput.inputs"
                    >
                        <span
                            class="zch-answer-text currency"
                            v-if="steps[conversation.currentStep].displayType === `currency`"
                        >
                            <img :src="input.country ? require(`../assets/flags/${input.country}.png`) : ''" alt="" />
                            <span>
                                <span class="code">{{ input.code }}</span>
                                <span class="currency-name">{{ input.text }}</span>
                            </span>
                        </span>
                        <span class="zch-answer-text" v-else v-bind:class="`${getAnswerDisplayType}`">
                            <span>{{ input.text }}</span>
                        </span>
                        <span
                            class="zch-answer-selected"
                            v-if="
                                steps[conversation.currentStep].displayType === `multiselect` &&
                                userInput.inputs[key].selected
                            "
                        >
                            <img src="../assets/tick-icon.svg" alt="" />
                        </span>
                    </div>
                </div>
                <SendButton
                    v-if="
                        isSelectionRequiringInput ||
                        (steps[conversation.currentStep] &&
                            steps[conversation.currentStep].displayType === `multiselect`)
                    "
                    :isSelectionValid="isSelectionValid()"
                    :submissionCursor="displaySubmissionCursor"
                    :displayType="steps[conversation.currentStep].displayType"
                    v-bind:style="getAnswerListStyle"
                    class="zch-answer-submit"
                />
            </div>
            <Background v-if="listToggled" />
            <LoginModal v-if="abandonedCartAuth" :email="this.auth.email" />
            <Hint v-if="toggledListName === `hint`" />
        </div>
    </div>
</template>

<script>
import AuthAPI from "@/api/auth";
import ConversationAPI from "@/api/conversation";
import CharitiesAPI from "@/api/charities";
import UserSvc from "@/service/user";
import ConversationSvc from "@/service/conversation";
import Currencies from "@/service/currencies";
import ValidatorSvc from "@/service/validator";
import Message from "@/components/Message/Message";
import Hint from "@/components/Hint/Hint";
import LoadingMessage from "@/components/LoadingMessage/LoadingMessage";
import SendButton from "@/components/SendButton/SendButton";
import SwitchButton from "@/components/SwitchButton/SwitchButton";
import LoginModal from "@/components/LoginModal/LoginModal";
import Background from "@/components/Background/Background";
import { stateHandler } from "@/main";
import { Step } from "@/models/Step";

const sprintf = require("sprintf-js").sprintf;

export default {
    name: "Chat",
    components: {
        Message,
        Hint,
        LoadingMessage,
        SendButton,
        SwitchButton,
        Background,
        LoginModal,
    },
    data() {
        return {
            messageSenders: {
                ZAK: "_ZAK",
                USER: "_USER",
            },
            conversationId: null,
            charityId: null,
            steps: [],
            msgClass: "",
            toggledListName: null,
            listToggled: false,
            selectedCharityId: null,
            isWidgetOrigin: false,
            isAppendingMessages: false,
            switchStyle: { margin: "0 8px" },
            switchLabelClass: ["toggled", ""],
            loadingMsgHidden: false,
            loadingMsgOpacity: "",
            giftAid: false,
            zakatReminder: true,
            marketingEmails: false,
            abandonedCartAuth: false,
            typingAnim: {
                awaitingSendersMessage: null,
                timeoutDuration: 900,
                hidden: false,
                lastMessageMargin: "",
            },
            userInput: {
                selectedField: null,
                fieldStyle: new Array(4).fill(undefined).map(() => ""),
                inputFieldValue: "",
                inputs: [],
                previousInputs: [],
                listDisplayed: false,
                dropdownToggled: false,
            },
            conversation: {
                questionKey: null,
                completed: false,
                question: "",
                currentStep: -1,
                completedStep: -1,
                currency: "",
            },
            zakatableAssets: null,
            awaitingResponse: false,
            auth: {
                email: "",
                session: "",
                token: "",
            },
            signUpMessages: {
                email: {
                    text: "Okay, now please insert your e-mail address.",
                    placeholder: "Insert your e-mail",
                    inputs: {
                        key: "auth-key",
                        type: "text",
                    },
                    displayType: "default",
                    message: "My e-mail is %s",
                },
                token: {
                    text: "You should've received the code in your inbox. Type it below.",
                    placeholder: "Insert the token",
                    inputs: {
                        key: "auth-key",
                        type: "number",
                    },
                    displayType: "default",
                    message: "The token I've received is %s.",
                },
            },
        };
    },
    methods: {
        async getFirstQuestion(charityId = null) {
            this.awaitingResponse = true;

            try {
                const response = await ConversationAPI.getFirstQuestion(charityId);
                this.conversationId = response.data.conversation_id;
                this.processResponseMessage(
                    response.data.text,
                    response.data.key,
                    response.data.hint,
                    response.data.inputs,
                    response.data.display_type
                );
            } catch (err) {
                this.handleResponseError(err.response.status);
            } finally {
                this.awaitingResponse = false;
            }
        },

        updateConversation(checkSelection = true) {
            if (checkSelection && (!this.isSelectionValid() || this.awaitingResponse)) {
                return;
            }
            let i = 0,
                message = this.generateUserMessageOutput();

            while (i < message.length) {
                setTimeout(
                    this.appendMessage,
                    this.typingAnim.timeoutDuration * i,
                    this.messageSenders.USER,
                    message[i],
                    i,
                    message.length,
                    this.conversation.currentStep
                );
                i++;
            }

            this.mutateAnswersListDisplay(false);
            this.toggledListName = "";
            this.awaitingResponse = true;

            let inputs = [];
            if (this.steps[this.conversation.currentStep].displayType === "multiselect") {
                for (let i = 0; i < this.userInput.inputs.length; i++) {
                    if (this.userInput.inputs[i].selected) {
                        inputs.push({
                            key: this.userInput.inputs[i].key,
                            value: "",
                        });
                    }
                    this.userInput.inputs[i].selected = false;
                }
            } else {
                inputs = [
                    {
                        key:
                            this.userInput.selectedField !== null
                                ? this.userInput.inputs[this.userInput.selectedField].key
                                : this.steps[this.conversation.currentStep].displayType === "switch"
                                ? this.getSwitchKey()
                                : this.userInput.inputs[0].key,
                        value:
                            this.userInput.inputs[0].type === "currency"
                                ? this.userInput.inputs[this.userInput.selectedField].code
                                : this.userInput.inputFieldValue,
                    },
                ];
            }

            const requestBody = {
                step: this.steps[this.conversation.currentStep].key,
                inputs: inputs,
            };

            setTimeout(async () => {
                this.resetInput();
                try {
                    const response = await ConversationAPI.updateConversation(requestBody, this.conversationId);
                    this.processResponseMessage(
                        response.data.text,
                        response.data.key,
                        response.data.hint,
                        response.data.inputs,
                        response.data.display_type
                    );
                } catch (err) {
                    this.handleResponseError(err.response.status);
                } finally {
                    this.awaitingResponse = false;
                }
            }, this.typingAnim.timeoutDuration * i);

            this.resetSwitch();
            this.switchLabelClass[0] = "toggled";
        },

        submitAnswer() {
            if (!this.conversation.completed) {
                return this.updateConversation();
            }

            if (!this.isSelectionValid()) {
                return;
            }
            this.mutateAnswersListDisplay(false);
            this.awaitingResponse = true;

            this.authConfirm();
            this.resetInput();
        },

        handleResponseError(statusCode) {
            const previousStep = this.steps[this.conversation.currentStep];

            this.conversation.currentStep++;
            this.steps.push({
                hint: previousStep.hint,
                inputs: previousStep.inputs,
                key: previousStep.key,
                stepId: this.conversationId.currentStep,
                userMessages: [],
                zakMessages: [],
            });
            if (statusCode === 400) {
                this.steps[this.conversation.currentStep].displayType =
                    this.steps[this.conversation.currentStep - 1].displayType;
                this.userInput.inputs = this.userInput.previousInputs;
                this.appendMessage(
                    this.messageSenders.ZAK,
                    "Oops! We'll try it again!",
                    null,
                    null,
                    this.conversation.currentStep
                );
                this.manageAnimMessage(this.messageSenders.ZAK);
                return this.mutateAnswersListDisplay(true);
            }
            this.appendMessage(
                this.messageSenders.ZAK,
                "Oh no! ZAK has got a headache. Try again later.",
                null,
                "last",
                this.conversation.currentStep
            );
            this.mutateAnswersListDisplay(false);
            this.typingAnim.hidden = true;
        },

        generateUserMessageOutput() {
            if (this.steps[this.conversation.currentStep].displayType === "multiselect") {
                let args = "",
                    selectedItems = 0;
                for (let i = 0; i < this.userInput.inputs.length; i++) {
                    if (this.userInput.inputs[i].selected) {
                        args = `${args} - ${this.userInput.inputs[i].text} \n`;
                        selectedItems++;
                    }
                }
                if (selectedItems === 1) {
                    args = args.replace("- ", "");
                }
                return this.splitMessageText(`${args.substring(0, args.length - 2)}.`);
            }

            if (this.steps[this.conversation.currentStep].displayType === "switch") {
                return this.splitMessageText(
                    sprintf(
                        this.switchLabelClass[0] !== "toggled"
                            ? this.userInput.inputs[0].message
                            : this.userInput.inputs[1].message,
                        this.userInput.inputFieldValue
                    )
                );
            }

            if (this.userInput.inputs[0].type === "currency") {
                this.conversation.currencySymbol = this.userInput.inputs[this.userInput.selectedField].symbol;
                return this.splitMessageText(
                    sprintf(this.userInput.inputs[0].message, this.userInput.inputs[this.userInput.selectedField].code)
                );
            }

            if (this.userInput.inputs[0].type === "email") {
                this.auth.email = this.userInput.inputFieldValue;
            }

            if (!this.userInput.selectedField) {
                return this.splitMessageText(sprintf(this.userInput.inputs[0].message, this.userInput.inputFieldValue));
            }

            return this.splitMessageText(
                sprintf(this.userInput.inputs[this.userInput.selectedField].message, this.userInput.inputFieldValue)
            );
        },

        getSwitchKey() {
            return this.switchLabelClass[0] === "toggled" ? this.userInput.inputs[1].key : this.userInput.inputs[0].key;
        },

        processResponseMessage(text, key, hint, inputs, displayType) {
            text = this.splitMessageText(text);

            this.conversation.currentStep++;
            this.steps.push(new Step(this.conversation.currentStep, [], [], hint, key, inputs, displayType));

            let i = 0;
            for (i; i < text.length; i++) {
                setTimeout(
                    this.appendMessage,
                    this.typingAnim.timeoutDuration * i,
                    this.messageSenders.ZAK,
                    text[i],
                    i,
                    text.length,
                    this.conversation.currentStep
                );
            }

            this.listToggled = false;
            this.userInput.inputs = inputs;

            if (!inputs || inputs.length === 0) {
                this.userInput.selectedField = 0;
                this.conversation.completed = true;
                this.conversation.currentStep++;
                this.steps.push(new Step(this.conversation.currentStep, [], [], null, "default", [], "default"));
                this.mutateAnswersListDisplay(false, true);
                return setTimeout(this.fetchAuthTokens, this.typingAnim.timeoutDuration * i);
            }
            setTimeout(this.mutateAnswersListDisplay, this.typingAnim.timeoutDuration * i, true, true);
        },

        async fetchAuthTokens() {
            if (UserSvc.getIDToken()) {
                return this.getSessionOverview();
            }
            if (this.auth.started) {
                return this.authConfirm();
            }
            this.awaitingResponse = true;

            let email = this.auth.email;

            this.steps.push(
                new Step(
                    this.conversation.currentStep,
                    [],
                    [],
                    "",
                    this.signUpMessages.email.inputs.key,
                    this.signUpMessages.email.inputs
                )
            );

            this.auth.started = true;
            const { data, err } = await AuthAPI.startTokenlessSignup(email);
            this.awaitingResponse = false;

            if (err) {
                return this.handleAuthErrors();
            }

            this.auth.email = email;
            if (data && data.id_token) {
                UserSvc.setIDToken(data.id_token);
                UserSvc.setAccessToken(data.access_token);
                UserSvc.setRefreshToken(data.refresh_token);

                this.conversation.currentStep++;

                this.getSessionOverview();
                this.mutateAnswersListDisplay(false);
                this.scrollToBottom();
                return;
            }

            this.conversation.currentStep++;

            let inputs = {
                key: this.signUpMessages.token.inputs.key,
                message: this.signUpMessages.token.message,
                type: this.signUpMessages.token.inputs.type,
                text: this.signUpMessages.token.text,
            };

            this.userInput.inputs = [];
            this.userInput.inputs.push(inputs);

            this.appendMessage(
                this.messageSenders.ZAK,
                this.signUpMessages.token.text,
                null,
                null,
                this.conversation.currentStep
            );
            this.manageAnimMessage(this.messageSenders.ZAK);
            this.mutateAnswersListDisplay(true, true);

            this.steps.push(
                new Step(this.conversation.currentStep, [], [], "", this.signUpMessages.token.inputs.key, inputs)
            );
            this.scrollToBottom();
        },

        async authConfirm() {
            if (UserSvc.getIDToken()) {
                return this.getSessionOverview();
            }

            this.awaitingResponse = true;

            let token = this.userInput.inputFieldValue.toString().trim();
            this.appendMessage(
                this.messageSenders.USER,
                `My one-time token is ${token}.`,
                null,
                null,
                this.conversation.currentStep
            );
            try {
                const response = await AuthAPI.confirmSession(this.auth.email, token);

                if (!response.data.success) {
                    this.auth.started = true;
                    return this.handleAuthErrors();
                }

                setTimeout(
                    () => {
                        this.auth.token = token;
                    },
                    this.typingAnim.timeoutDuration,
                    token
                );

                UserSvc.setIDToken(response.data.id_token);
                UserSvc.setAccessToken(response.data.access_token);
                UserSvc.setRefreshToken(response.data.refresh_token);

                this.conversation.currentStep++;

                this.getSessionOverview();
                this.mutateAnswersListDisplay(false);
                this.scrollToBottom();
            } catch (e) {
                this.handleAuthErrors();
            } finally {
                this.awaitingResponse = false;
            }
        },

        async getSessionOverview() {
            this.awaitingResponse = true;
            this.mutateAnswersListDisplay(false);

            try {
                const response = await ConversationAPI.getZakatDetails(this.conversationId);
                if (response.data.total <= 0) {
                    const messages = [
                        "Oh! You actually don’t need to pay Zakat 😃",
                        "Thanks for checking though",
                        "Let’s meet again next year",
                        "and I’ll do another quick check for you.",
                        "Enjoy the rest of your Ramadan, and stay home and save lives.",
                    ];

                    for (let i = 0, len = messages.length; i < len; i++) {
                        setTimeout(() => {
                            this.appendMessage(
                                this.messageSenders.ZAK,
                                messages[i],
                                null,
                                "last",
                                this.conversation.currentStep,
                                i === len - 1 ? "last" : null
                            );
                        }, this.typingAnim.timeoutDuration * i);
                    }

                    this.hideLoadingMessage();
                    this.mutateAnswersListDisplay(false);
                    return;
                }
                this.appendMessage(
                    this.messageSenders.ZAK,
                    `Great! You have zakatable assets. Click below to view.`,
                    null,
                    "last",
                    this.conversation.currentStep
                );
                ConversationSvc.setConversationID(this.conversationId);
                this.updateAnswerSelection([
                    {
                        key: "confirm",
                        text: "View my Zakat",
                        message: "",
                    },
                ]);

                this.setLoadingMessageOpacity(0);

                this.zakatableAssets = response.data;
                this.mutateAnswersListDisplay(true, true);
                this.scrollToBottom();
            } catch (err) {
                if (err.isAuth) {
                    return this.handleAuthErrors();
                }
                this.handleResponseError(500);
            } finally {
                this.awaitingResponse = false;
            }
        },

        handleAuthErrors(hasOutput = true) {
            this.manageAnimMessage(this.messageSenders.USER);

            this.auth.token = "";
            this.auth.started = false;

            UserSvc.clearSession();

            let previousStep = this.steps[this.conversation.currentStep];

            this.conversation.currentStep++;
            this.steps.push({
                displayType: previousStep.displayType,
                hint: previousStep.hint,
                inputs: previousStep.inputs,
                key: previousStep.key,
                stepId: this.conversationId.currentStep,
                userMessages: [],
                zakMessages: [],
            });

            setTimeout(() => {
                if (hasOutput) {
                    this.appendMessage(
                        this.messageSenders.ZAK,
                        "We could not verify your token. Please try again.",
                        null,
                        null,
                        this.conversation.currentStep
                    );
                }
                setTimeout(() => {
                    this.updateAnswerSelection([this.signUpMessages.email.inputs]);
                    this.mutateAnswersListDisplay(true, true);
                }, this.typingAnim.timeoutDuration);
            }, this.typingAnim.timeoutDuration);
        },

        appendMessage(sender, text, iter = null, maxIterations = null, stepIndex, hint = null) {
            this.isAppendingMessages = true;

            let message = {
                    text: text,
                    hint: hint,
                },
                inputs = this.steps[stepIndex].inputs ? this.steps[stepIndex].inputs : [];

            sender === this.messageSenders.ZAK
                ? this.steps[stepIndex].zakMessages.push(message)
                : this.steps[stepIndex].userMessages.push(message);

            this.scrollToBottom();
            if (iter !== null && iter + 1 === maxIterations) {
                setTimeout(
                    () => {
                        if (!this.conversation.completed) {
                            this.updateAnswerSelection(inputs);
                            this.scrollToBottom();
                        }
                        this.manageAnimMessage(sender);

                        this.isAppendingMessages = false;
                    },
                    !this.conversation.completed ? this.typingAnim.timeoutDuration : 0,
                    inputs
                );
            } else {
                return this.manageAnimMessage(this.messageSenders.USER);
            }
        },

        splitMessageText(message) {
            return message.split("\n").filter((t) => t.length > 0);
        },

        requireAuthenticationInput(authStep) {
            this.mutateAnswersListDisplay(true, false);
            if (UserSvc.getIDToken()) {
                return this.getSessionOverview();
            }

            if (authStep === "token") {
                this.appendMessage(
                    this.messageSenders.ZAK,
                    this.signUpMessages["token"].text,
                    null,
                    null,
                    this.conversation.currentStep
                );
            }

            this.manageAnimMessage(this.messageSenders.ZAK);
            if (this.conversation.completed) {
                this.updateAnswerSelection([this.signUpMessages[authStep].inputs]);
            }
        },

        setLoadingMessageOpacity(opacity) {
            this.loadingMsgOpacity = `opacity: ${opacity}`;
        },

        resetInput() {
            this.userInput.selectedField = null;
            this.userInput.inputFieldValue = "";
            this.userInput.fieldStyle = this.userInput.fieldStyle.map(() => "");
        },

        async undoMessage() {
            if (this.awaitingResponse) {
                return;
            }
            this.awaitingResponse = true;

            this.mutateAnswersListDisplay(false, false);

            this.steps = this.steps.splice(0, this.steps.length - 1);
            if (this.conversation.completed) {
                this.conversation.completed = false;
            }
            this.conversation.currentStep--;
            this.steps[this.conversation.currentStep].userMessages = [];
            this.userInput.inputs = this.steps[this.conversation.currentStep].inputs;
            this.typingAnim.hidden = false;

            try {
                const resp = await ConversationAPI.undoStep(
                    this.steps[this.conversation.currentStep].key,
                    this.conversationId
                );
                if (resp.data["display_type"] === "currency") {
                    this.userInput.inputs = [];
                    this.setCurrencyInputs(resp.data.inputs);
                } else {
                    this.userInput.inputs = resp.data.inputs;
                    this.steps[this.conversation.currentStep].inputs = resp.data.inputs;
                }
                this.mutateAnswersListDisplay(true, true);
            } catch (err) {
                this.handleResponseError(err.response.status);
            } finally {
                this.awaitingResponse = false;
                this.userInput.inputFieldValue = "";
            }
        },

        setCurrencyInputs(inputs) {
            for (let i = 0, len = Currencies.length; i < len; i++) {
                const input = {
                    key: inputs[0].key,
                    message: inputs[0].message,
                    text: Currencies[i].name,
                    type: inputs[0].type,
                    code: Currencies[i].code,
                    symbol: Currencies[i].left,
                    country: Currencies[i].country,
                    color: Currencies[i].color,
                };
                this.userInput.inputs.push(input);
                this.steps[this.conversation.currentStep].inputs.push(input);
            }
        },

        updateAnswerSelection(inputs) {
            if (inputs[0].type === "currency") {
                this.userInput.inputs = [];
                this.steps[this.conversation.currentStep].inputs = [];

                this.setCurrencyInputs(inputs);
                return;
            }
            this.userInput.previousInputs = this.userInput.inputs;
            this.userInput.inputs = inputs;
        },

        manageAnimMessage(sender) {
            this.typingAnim.awaitingSendersMessage =
                sender === this.messageSenders.USER ? this.messageSenders.ZAK : this.messageSenders.USER;
            this.$forceUpdate();
            this.scrollToBottom();
        },

        async getWidgetCharity(charityId) {
            try {
                await CharitiesAPI.fetchCharity(charityId);
            } catch (err) {
                this.typingAnim.hidden = true;
                this.conversation.currentStep++;
                const message = "We are sorry but right now this charity is not available.";

                const step = new Step(
                    this.conversation.currentStep,
                    [
                        {
                            text: message,
                            hint: "last",
                        },
                    ],
                    [],
                    null,
                    "",
                    [],
                    "default"
                );

                this.conversation.currentStep++;
                this.steps.push(step, new Step(this.conversation.currentStep));
                return;
            }

            this.isWidgetOrigin = true;
            this.selectedCharityId = charityId;
            UserSvc.setCharityId(this.selectedCharityId);
            this.getFirstQuestion(this.selectedCharityId);
        },

        hideLoadingMessage() {
            this.loadingMsgHidden = true;
        },

        scrollToBottom() {
            const app = document.body.querySelector(".zch-c-app");
            const visibleChatPosition = app.scrollHeight - window.innerHeight;
            app.scroll({
                top: visibleChatPosition > 0 ? visibleChatPosition : 0,
                behavior: "smooth",
            });
        },

        selectOption(optionIndex) {
            if (this.awaitingResponse || !this.isAnswerListDisplayedProperly()) {
                return;
            }
            if (this.conversation.completed) {
                window.location = `/zakat`;
            }

            this.userInput.fieldStyle[optionIndex] = "active";

            if (this.steps[this.conversation.currentStep].displayType === "multiselect") {
                if (this.userInput.inputs[optionIndex].type === "excluding") {
                    for (let i = 0; i < this.userInput.inputs.length; i++) {
                        if (i !== optionIndex) {
                            this.userInput.inputs[i].selected = false;
                            this.userInput.fieldStyle[i] = "";
                        }
                    }
                    this.userInput.inputs[optionIndex].selected = true;
                    this.userInput.fieldStyle[optionIndex] = "active";
                } else {
                    for (let i = 0; i < this.userInput.inputs.length; i++) {
                        if (this.userInput.inputs[i].type === "excluding") {
                            this.userInput.inputs[i].selected = false;
                            this.userInput.fieldStyle[i] = "";
                        }
                    }
                    if (this.userInput.inputs[optionIndex].selected) {
                        this.userInput.fieldStyle[optionIndex] = "";
                        this.userInput.inputs[optionIndex].selected = false;
                    } else {
                        this.userInput.inputs[optionIndex].selected = true;
                    }
                }
                return this.$forceUpdate();
            } else {
                this.userInput.selectedField = optionIndex;
                this.userInput.fieldStyle = this.userInput.fieldStyle.map(() => "");
            }

            this.submitAnswer();
        },

        mutateAnswersListDisplay(isDisplayed, needsMarginUpdate = false) {
            setTimeout(
                () => {
                    if (needsMarginUpdate) {
                        try {
                            const answersList = this.$refs.answersList;
                            this.typingAnim.lastMessageMargin = `margin-bottom: 20px;`;
                            answersList.scrollLeft = 0;
                        } catch (err) {
                            console.error(err);
                        }
                    }

                    this.scrollToBottom();
                    setTimeout(this.scrollToBottom, 500);
                },
                50,
                needsMarginUpdate
            );
            this.userInput.listDisplayed = isDisplayed;
            return;
        },

        isAnswerListDisplayedProperly() {
            const a = this.$refs.answersList;
            return parseFloat(window.getComputedStyle(a).getPropertyValue("opacity")) > 0.65;
        },

        isSelectionValid() {
            if (this.steps[this.conversation.currentStep].displayType === "multiselect") {
                for (let i = 0; i < this.userInput.inputs.length; i++) {
                    if (this.userInput.inputs[i].selected) {
                        return true;
                    }
                }
                return false;
            }

            if (!this.isAnswerListDisplayedProperly()) {
                return false;
            }

            // If the chat has been completed, proceed to validation of auth data.
            if (this.conversation.completed) {
                return this.isAuthInputValid();
            }

            if (this.userInput.inputs[0].type === "number") {
                return this.isNumberAndBelowMax(this.userInput.inputFieldValue, 10000000);
            }

            if (this.userInput.inputs[0].type === "percentage") {
                return this.isNumberAndBelowMax(this.userInput.inputFieldValue, 100);
            }

            if (this.userInput.inputs[0].type === "email") {
                return ValidatorSvc.isValidEmail(this.userInput.inputFieldValue.trim());
            }

            // Validating that an option from the answers list has been selected by the user.
            return !(this.userInput.selectedField === null && this.userInput.inputFieldValue.length < 1);
        },

        isAuthInputValid() {
            // If the token has 6 digits, it is valid until further validation by the back-end.
            return this.userInput.inputFieldValue.length == 6;
        },

        getAnswerStyle(input) {
            if (input.type === "currency") {
                return `background: ${input.color}`;
            }

            return "";
        },

        handleEvents() {
            stateHandler.$on("onUserUndoMessage", () => {
                this.undoMessage();
            });

            stateHandler.$on("userSupportClosed", () => {
                this.enableScroll();
            });

            stateHandler.$on("onUserToggleList", (list) => {
                const isDisplayable = !this.conversation.completed && this.userInput.inputs.length > 0;
                if (isDisplayable) {
                    this.mutateAnswersListDisplay(this.listToggled, false);
                }
                setTimeout(
                    () => {
                        this.listToggled = !this.listToggled;
                        this.toggledListName = this.listToggled ? list : null;
                        this.msgClass = this.listToggled ? "menu-opened" : "";
                        if (isDisplayable) {
                            this.mutateAnswersListDisplay(!this.listToggled, false);
                        }
                    },
                    !this.listToggled ? 300 : 0
                );
            });

            stateHandler.$on("onUserClickSend", () => {
                this.submitAnswer();
            });

            stateHandler.$on("onInputSwitchUpdate", (val) => {
                this.resetSwitch();
                if (val) {
                    this.switchLabelClass[0] = "toggled";
                    return;
                }
                this.switchLabelClass[1] = "toggled";
            });

            stateHandler.$on("onLoginSucceed", (data) => {
                this.abandonedCartAuth = false;
                UserSvc.setIDToken(data.idToken);
                UserSvc.setAccessToken(data.accessToken);
                UserSvc.setRefreshToken(data.refreshToken);

                this.loadConversation(this.conversationId);
            });
        },

        resetSwitch() {
            this.switchLabelClass = this.switchLabelClass.map(() => "");
        },

        getCompletedWrapperMargin(wrapperId) {
            return this.conversation.completed && wrapperId === this.steps.length - 1 && this.typingAnim.hidden
                ? this.typingAnim.lastMessageMargin
                : 0;
        },

        getLastStyle(msg) {
            return msg.hint === "last" ? "margin-bottom: 20px" : "";
        },

        isNumberAndBelowMax(val, max) {
            return !isNaN(parseInt(val)) && val % 1 === 0 && val >= 0 && val <= max;
        },

        async loadConversation(conversationId) {
            const { resp, err } = await ConversationAPI.fetchConversation(conversationId);

            if (err || !resp.data.email) {
                UserSvc.clearSession();
                return this.loadConversation(conversationId);
            }

            const data = resp.data;

            this.auth.email = data.email;
            if (data["auth_required"]) {
                this.abandonedCartAuth = true;
                return;
            }

            this.abandonedCartAuth = false;
            this.currency = data.currency;
            this.conversation.currencySymbol = data["currency_symbol"];

            let zakMessageTexts = [],
                zakMessages = [],
                userMessages = [];

            const len = data.steps.length;
            const inputs = data.steps[len - 1].inputs;

            if (inputs) {
                this.userInput.inputs = inputs;
                this.mutateAnswersListDisplay(true, true);
            } else {
                this.conversation.completed = true;
            }

            for (let i = 0; i < len; i++) {
                zakMessageTexts = this.splitMessageText(data.steps[i].text);
                zakMessages = [];
                userMessages = [];

                for (let j = 0, len = zakMessageTexts.length; j < len; j++) {
                    zakMessages.push({
                        hint: data.steps[i].hint,
                        text: zakMessageTexts[j],
                    });
                }

                if (data.steps[i].inputs && data.steps[i].inputs !== null) {
                    if (i !== len - 1) {
                        userMessages.push({
                            hint: "",
                            text: sprintf(data.steps[i].inputs[0].message, data.steps[i].inputs[0].value),
                        });
                    }
                }

                this.conversation.currentStep++;
                const step = new Step(
                    this.conversation.currentStep,
                    zakMessages,
                    userMessages,
                    "",
                    data.steps[i].key,
                    data.steps[i].inputs,
                    data.steps[i]["display_type"]
                );

                this.steps.push(step);

                this.manageAnimMessage(this.messageSenders.ZAK);
            }

            if (!inputs) {
                if (UserSvc.getIDToken()) {
                    return this.getSessionOverview();
                }
                return this.fetchAuthTokens();
            }
        },
    },
    computed: {
        displaySubmissionCursor() {
            return this.isSelectionValid() ? "cursor: pointer; background-color: #80d1b6;" : "";
        },

        getAnimMessageSenderClass() {
            return this.typingAnim.awaitingSendersMessage === this.messageSenders.USER
                ? "zch-user-message"
                : "zch-bot-message";
        },

        getAnswersListDisplay() {
            return this.userInput.listDisplayed ? "" : "zch-answers-list-hidden";
        },

        getAnswerListInputClass() {
            return this.isSelectionRequiringInput ? "input" : "";
        },

        getAuthPlaceholder() {
            return this.signUpMessages.token.placeholder;
        },

        getInputPlaceholder() {
            if (this.steps[this.conversation.currentStep].displayType === "switch") {
                return this.switchLabelClass[0] === "toggled"
                    ? this.steps[this.conversation.currentStep].inputs[1].text
                    : this.steps[this.conversation.currentStep].inputs[0].text;
            }

            return this.userInput.inputs.length === 1
                ? !this.conversation.completed
                    ? this.userInput.inputs[0].text
                    : this.getAuthPlaceholder
                : this.userInput.selectedField
                ? this.userInput.inputs[this.userInput.selectedField].text
                : null;
        },

        getAnimMessageStyle() {
            return this.typingAnim.hidden ? "display: none;" : this.typingAnim.lastMessageMargin;
        },

        getAnswerListStyle() {
            return this.typingAnim.hidden ? "display: none;" : "";
        },

        getAnswerListTypeStyle() {
            return this.steps[this.conversation.currentStep] &&
                this.steps[this.conversation.currentStep].displayType === "multiselect"
                ? "padding-bottom: 80px"
                : "";
        },

        isSelectionRequiringInput() {
            try {
                let inputType = this.userInput.selectedField
                    ? this.userInput.inputs[this.userInput.selectedField].type
                    : this.userInput.inputs[0].type;
                return (
                    inputType === "number" ||
                    inputType === "percentage" ||
                    inputType === "text" ||
                    inputType === "email"
                );
            } catch (err) {
                return false;
            }
        },

        getFieldInputType() {
            return this.userInput.inputs[0].type === "number" || this.userInput.inputs[0].type === "percentage"
                ? "number"
                : "text";
        },

        getFieldPattern() {
            return this.userInput.inputs[0].type === "number" || this.userInput.inputs[0].type === "percentage"
                ? "[0-9]*"
                : "";
        },

        getAnswerDisplayType() {
            return this.steps[this.conversation.currentStep]
                ? this.steps[this.conversation.currentStep].displayType
                : "default";
        },

        isDisplayTypeSwitch() {
            return this.steps[this.conversation.currentStep].displayType === "switch";
        },

        getAnswersLength() {
            return `len-${this.userInput.inputs.length}`;
        },
    },
    mounted() {
        this.handleEvents();

        UserSvc.setCharityId("");
        const charityId = this.$route.params.charityId;
        if (this.$route.params.charityId) {
            return this.getWidgetCharity(charityId);
        }

        const conversationId = this.$route.params.conversationId;
        if (!conversationId) {
            UserSvc.clearSession();
            return this.getFirstQuestion();
        }

        this.conversationId = conversationId;
        this.loadConversation(conversationId);
    },
};
</script>

<style lang="scss">
.zch-chat-body {
    display: flex;
    min-height: 100vh;
    background-color: $color-chat-background;
}

.zch-messages {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-top: 5vh;

    @media screen and (max-width: 992px) {
        margin-left: 0;
        padding-top: 10px;
    }
}

.zch-answers-list {
    @include list;
}

.zch-answer {
    @include answer;
}

.zch-answer.active .zch-answer-text {
    font-family: Montserrat, serif;
    font-size: 16px;
    line-height: 1.71;
    color: #000;
}

.zch-input-field {
    border: none;
    border-bottom: 1px solid #b3b3b344;
    background-color: white;
    width: 100%;
    height: 71px;
    font-family: Montserrat, serif;
    font-size: 16px;
    color: black;
    font-weight: bold;

    &-arrow {
        margin-left: -40px;
        position: relative;
        cursor: pointer;
        transition: 500ms;
    }

    @media screen and (max-width: 992px) {
        font-size: 16px;
    }
}

.zch-step-wrapper {
    display: flex;
    flex-direction: column;
    margin-top: 12px;

    & > div {
        display: flex;
        flex-direction: column;
    }
}

@keyframes fly-in {
    0% {
        transform: scale(0.85) translateY(10%);
        opacity: 0;
    }
    100% {
        transform: scale(1) translateY(0);
        opacity: 1;
    }
}

.menu-opened {
    position: fixed;
    overflow-y: hidden;

    @media screen and (min-width: 992px) {
        height: 100%;
    }
}

.zch-answers {
    width: 100%;
    display: flex;

    &.multiselect {
        @media screen and (min-width: 992px) {
            overflow: hidden;
            height: 160px;
            flex-wrap: wrap;
            display: flex;
            width: 82%;
        }
    }

    &.yes-no {
        flex-direction: row;
        justify-content: space-evenly;
        align-items: center;
    }
}
</style>
