export class Step {
    constructor(
        stepId,
        zakMessages = [],
        userMessages = [],
        hint = null,
        key = "",
        inputs = [],
        displayType = "default"
    ) {
        this.stepId = stepId;
        this.zakMessages = zakMessages;
        this.userMessages = userMessages;
        this.hint = hint;
        this.key = key;
        this.inputs = inputs;
        this.displayType = displayType;
    }
}
