<template>
    <div class="zch-send-btn" v-bind:class="getButtonClass" v-bind:style="getBackgroundColor" @click="send()">
        <img src="../../assets/send.svg" alt="" />
    </div>
</template>

<script>
import { stateHandler } from "../../main";

export default {
    name: "SendButton",
    props: {
        submissionCursor: String,
        isSelectionValid: Boolean,
        displayType: String,
    },
    methods: {
        send() {
            stateHandler.$emit("onUserClickSend");
        },
    },
    computed: {
        getBackgroundColor() {
            return this.isSelectionValid ? "cursor: pointer; background-color: #80d1b6;" : "";
        },
        getButtonClass() {
            return this.displayType;
        },
    },
};
</script>

<style lang="scss" scoped>
.zch-send-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 70px;
    max-width: 70px;
    height: 70px;
    border-radius: 18px;
    background-color: #d8d9df;
    padding: 0;
    &.multiselect {
        position: fixed;
        width: 100%;
        max-width: 100%;
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0;
        right: 0;
        bottom: 0;
        @media screen and (min-width: 992px) {
            width: 72px;
            position: initial;
            margin: auto;
            border-radius: 13px;
        }
    }
}
</style>
